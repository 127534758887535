.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    z-index: 9999; /* Ensure the confetti is above other elements */
  }
  
  .confetti {
    position: absolute;
    opacity: 0.8;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(-10vh) rotate(0deg);
    }
    100% {
      transform: translateY(110vh) rotate(360deg);
    }
  }
  