@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: 'Poppins', sans-serif;
                         

}

/* src/fonts/YourCustomFont.css */
body{
  background-color:#083ADB !important ;
  overflow-x: hidden !important;  
}

.bg {


  background-repeat: no-repeat !important;
  background-size: cover !important;
    background:#083ADB ;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center !important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
 
    z-index: 1111;
  }
  .pageCon {
    background: #083ADB;
    background: linear-gradient(0deg, rgb(255, 119, 0) 0%, rgba(255, 255, 255, 0.908) 46%,  rgba(255, 255, 255, 0.99) 86%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
  }

  .title {
    font-size: 45px;
    color: #fff;
    text-align: center;
    text-stroke: 2px #000; /* Set the border color and width */
    -webkit-text-stroke: 2px #FFDF3A; /* For webkit browsers like Chrome and Safari */
  }
  
  @media screen and (min-width: 1000px) {
    .title {
      font-size: 103px;
      text-align: start;
    }
  }
  
  .footer_title{
    font-size: 40px;
    color: #000000;
    
  }
  @media screen and (min-width: 1000px){
    .footer_title{
      font-size: 70px;
      color: #000000;
    }
  }
  .navbartop{
    background-color: #626159;
  }

  .backofdesktop{
    background-repeat: no-repeat !important;
    background-size: cover !important;
   
      background-repeat: no-repeat!important;
      background-size: cover!important;
      height: 100%;
     background-color: #083ADB;
      background-position: center !important;
  }
.footer{
  background-color: #ff9c51;
}
  .centeritall{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .end{
    text-align: end;
 
    justify-content: end;
    display: flex;
  }

  .top_logo{
    width: 50%;
  }
  @media screen and (min-width: 1000px){
    .top_logo{
             width: 60%;
    }
  }
  

.embrace{
  color: #000000;
  font-weight: 700;
  font-size: 11px;
}
@media screen and (min-width: 1000px){
  .embrace{
    color: #000000;
    font-weight: 700;
    font-size: 16px;
  }
  
}




.embraces{
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}













.startfrom{
  justify-content: start;
  align-items: start;
  text-align: start;

}
.aboutimg{
  width: 40%;
}
.about{
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 1000px){
  .about{
    font-size: 20px;
    font-weight: 400;
  }
}
.centerps{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #000000;

}


.ps{
  font-size: 25px;
  
}



.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #083ADB !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}






.bgst{
  background-repeat: no-repeat !important;
  background-size: cover !important;

    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;
   background-color: #000000;
    background-position: center !important;
}



.bgnavt {
  background-color: #ffffff;
  color: #dd0000;
  text-decoration: underline !important;
}


button {
  position: relative;
  height: 50px;
  padding: 0 30px;
  border: 2px solid #000;
  background: #e8e8e8;
  user-select: none;
  white-space: nowrap;
  transition: all .05s linear;
  font-family: inherit;
 }
 




 

 button:before, button:after {
  content: "";

  position: absolute;
  background: #000000;
  transition: all .2s linear;
 }
 
 button:before {
  width: calc(100% + 6px);
  height: calc(100% - 16px);
  top: 8px;
  left: -3px;
 }
 
 button:after {
  width: calc(100% - 16px);
  height: calc(100% + 6px);
  top: -3px;
  left: 8px;
 }
 
 button:hover {
  cursor: crosshair;
 }
 
 button:active {
  transform: scale(0.95);
 }
 
 button:hover:before {
  height: calc(100% - 32px);
  top: 16px;
 }
 
 button:hover:after {
  width: calc(100% - 32px);
  left: 16px;
 }
 
 button span {
  font-size: 15px;  color: #fff !important;
  z-index: 3;
  position: relative;
  font-weight: 600;
 }
/* App.css */
body, .custom-cursor {
  cursor: url('./mouse.png'), auto;
}


 .bgsts{
  background-color: #fff !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
   
    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;

    background-position: center !important;

 }


 
 .titlepl {
  font-size: 20px;
  color: #000000;
  text-align: center;
  
  
}

@media screen and (min-width: 1000px) {
  .titlepl {
    font-size: 27px;

      text-align: center;
  }
}


.navbartops{
  background-color: #083ADB;
height: 100vh;
overflow-y: hidden;

}

.navbartopsm{
  background-color: #083ADB;
height: 100%;

}

.startlogo{
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .startlogo{
    width: 85% !important;
  }
}


.enterlnd{
  font-size: 20px;
  color: #e8e8e8;
}

@media screen and (min-width: 1000px) {
  .enterlnd{
    font-size: 40px;
    color: #e8e8e8;
  }
}



























.arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}



.bgca{
  background-color: #000;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  color: #fff;
  border: 6px solid #626159;
  font-size: 12px;
}

@media screen and (min-width: 1000px) {
  .bgca{
    background-color: #000;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    color: #fff;
    border: 6px solid #626159;
    font-size: 20px;
  }
  
}




















 .elementor-button {
 
}


.elementor-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #0073e6;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  fill: var(--e-global-color-primary);
  color: #083ADB;
  text-decoration: none !important;
 background-color: #FFDF3A;
  border-radius: 60px 60px 60px 60px;
  padding: 12px 35px 12px 35px;
}

.elementor-button:hover {
  background-color: #ffffff;
  transform: scale(1.1);
}

.elementor-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.5s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.elementor-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}





















































@keyframes rotate3D {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.startlogo {
  display: block;
  width: 100%;
  max-width: 500px; /* Adjust as needed */
  height: auto;
  margin: 0 auto;
  animation: rotate3D 2s infinite linear;
  transform-style: preserve-3d; /* Ensure the 3D effect is maintained */
}

.startlogo:hover {
  animation-play-state: paused;
}

/* Media Queries for different screen sizes */
@media (max-width: 600px) {
  .startlogo {
    max-width: 100%; /* Adjust as needed */
  }
}

@media (max-width: 400px) {
  .startlogo {
    max-width: 100%; /* Adjust as needed */
  }
}


.start_mog{
  font-size: 50px;
  color: #fff;
}












.mog_top_text{
  font-size: 30px;
  text-align: center;
  color: #FFDF3A;
  text-shadow: 4px 4px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000 !important;
  font-weight: 600;
}
@media screen and (min-width: 1000px){
  .mog_top_text{
    font-size: 120px;
    text-align: center;
  }
}
.mog_top_text_ppt{
  font-size: 30px;
  text-align: center;
  color: #ffffff;
 
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .mog_top_text_ppt{
    font-size: 40px;
    text-align: center;
  }
}



.mog_top_text_under{
  font-size: 25px;
  text-align: center;
  color: #FFDF3A;
  text-shadow: 4px 4px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000 !important;
  font-weight: 600;
}
@media screen and (min-width: 1000px){
  .mog_top_text_under{
    font-size: 40px;
    text-align: center;
  }
}




























.containerz {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.image {
  max-width: 90%; /* Ensures the image doesn't exceed the container width */
  max-height: 100%; /* Ensures the image doesn't exceed the container height */
  border: 1px solid transparent; /* Initial border style */
  transition: border-color 0.3s ease; /* Smooth transition for border color change */
  border: 7px solid #FFDF3A;
}

/* Add 3D border effect */
.image:hover {

}

/* Add hover animation */
.image:hover {
  transform: scale(1.1); /* Scale up the image on hover */
}











.image_all {
  width: 50px;
  animation: popout 1s infinite;
}

@keyframes popout {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



@media screen and (min-width: 1000px){
  .image_all {
    animation: popout 1s infinite;
    width: 200px;
  }
  
}





















.first_marque {
  background-color: #ffa63a;
  color: rgb(0, 0, 0);
  height: 6vh;

}
@media screen and (min-width: 1000px){
  .first_marque {
    background-color: #FFDF3A;
    color: rgb(0, 0, 0);
    height: 10vh;
  
  }
  
}



.two_marque {
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  color: #000000;
  height: 6vh;

}

@media screen and (min-width: 1000px){
  .two_marque {
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
    color: #000000;
    height: 10vh;
  
  }
  
  
}



 .p3 {
font-weight: 400 !important;
  font-size: 23px !important;
  color: #083adb !important;
}


@media screen and (min-width: 1000px){
  .p3 {
    font-weight: 400 !important;
      font-size: 22px !important;
      color: #083adb !important;
    }
    
  
}













.ball {
  position: absolute;
  cursor: pointer;
}

.ball img {
  width: 140px; /* Adjust the size of your ball image */
  height: 100px; /* Adjust the size of your ball image */
}
















.center {
  display: flex;
  justify-content: center;
  align-items: center;
 
}







































/* src/App.css */

.App {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

input[type="file"] {
  margin: 20px 0;
}

input[type="submit"] {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.preview-box {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

p {
  margin-top: 20px;
  color: green;
}

@media (max-width: 600px) {
  .preview-box {
    width: 80px;
    height: 80px;
  }

  input[type="submit"] {
    width: 100%;
    padding: 15px 0;
  }
}
